
import { defineComponent, ref, reactive, watch, toRefs, toRef, onMounted, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import http from '@/http'
import API, { otherUrl } from '@/http/api'
import { showLoading } from '@/utils/loading'
import QRCode from 'qrcodejs2'
import HelpSearchBar, { HelpSearchProps } from '@/components/HelpSearchBar.vue'

export default defineComponent({
  name: 'HelpCenter',
  components: { HelpSearchBar },
  setup() {
    function handleSelect(index: any) {
      // console.log(index)
      treeProps.activeIndex = index
      treeProps.htmlText = ''
      titleArr.value = []
      if (treeProps.activeIndex === '3') {
        getUpdateLog()
      } else {
        getMenuList()
      }
    }
    const route = useRoute()
    // console.log(route.query)
    let searchData: HelpSearchProps = {}
    if (route.query) {
      searchData = { ...searchData, ...route.query }
    }
    let platform = searchData.platform || '1'
    let project = searchData.project || '1'

    let titleArr = ref<any>([])
    let menuDatas = reactive<any>([])
    const qrcodeRef = ref(null)
    let qrcode: any
    let updateLogList = reactive<any>([])
    const treeRef = ref()
    const treeProps = reactive({
      filterText: '',
      loading: false,
      helpTitle: '',
      currentSelectId: 0,
      // platform: searchData.platform || '1',
      // project: searchData.project || '1',
      htmlText: '',
      pages: 1,
      total: 0,
      activeIndex: '1',
      curSelectId: '',
      searchData,
      keyword: ''
    })

    // 获取当前点击的node
    function clickChildren(dataValue: any, node: any) {
      titleArr.value = []
      let nodeObj = node
      for (let i = 0; i < node.level; i++) {
        titleArr.value.push(nodeObj.data.cateName)
        nodeObj = nodeObj.parent
      }
      // 倒序排列
      titleArr.value = titleArr.value.reverse()
      treeProps.loading = true
      const data = { cateId: dataValue.id }
      showLoading('#content-scrollbar')
      http({
        url: API.findContentByCateIdApi,
        method: 'post',
        data
      }).then(res => {
        treeProps.htmlText = ''
        if (res.data.length) {
          treeProps.htmlText = res.data[0].webContent
        }
        treeProps.loading = false
      })
    }
    // 筛选过滤
    watch(toRef(treeProps, 'filterText'), (newProps, oldProps) => {
      console.log(oldProps)
      treeRef.value.filter(newProps)
    })
    function filterNode(value: any, data: any) {
      console.log(value)
      if (!value) return true
      return data.cateName.indexOf(value) !== -1
    }
    // 获取菜单
    function getMenuList() {
      showLoading('#tree_box')
      const data = {
        module: treeProps.activeIndex,
        platform: platform,
        project: project,
        isMobile: '0'
      }
      http({
        url: API.findCategoryListByTreeApi,
        method: 'post',
        data
      }).then(res => {
        menuDatas.length = 0
        const arr = Array.prototype.concat.call(res.data)
        menuDatas.push(...arr)

        const qrcodeContent = `${otherUrl}/#/help-center?platform=${platform}&project=${project}`
        qrcode.makeCode(qrcodeContent)
      })
    }

    nextTick(() => {
      generateQrcode()
    })

    function generateQrcode() {
      qrcode = new QRCode(qrcodeRef.value, {
        width: 150, // 二维码的宽
        height: 150, // 二维码的高
        // text: qrcodeContent,
        colorDark: '#000', // 二维码的颜色
        colorLight: '#fff',
        correctLevel: QRCode.CorrectLevel.H
      })
      // qrcode.clear()
    }
    // 获取更新日志
    function getUpdateLog() {
      showLoading('#tree_box')
      const data = {
        date: treeProps.keyword,
        platform: platform,
        project: project,
        pageSize: 10,
        page: treeProps.pages
      }
      http({
        url: API.selectHelpLog,
        method: 'post',
        data
      }).then(res => {
        console.log(res)
        updateLogList.length = 0
        const arr = Array.prototype.concat.call(res.data.records)
        updateLogList.push(...arr)
        treeProps.total = res.data.total
      })
    }
    // 选中当前点击日志
    function clickItem(val: any) {
      treeProps.htmlText = val.webContent
      treeProps.curSelectId = val.id
      titleArr.value = []
      titleArr.value.push(val.date)
    }
    function helpScreenQuery(screenValue: any) {
      platform = screenValue.platform
      project = screenValue.project
      if (treeProps.activeIndex === '3') {
        getUpdateLog()
      } else {
        getMenuList()
      }
    }

    onMounted(() => {
      getMenuList()
    })

    return {
      qrcodeRef,
      menuDatas,
      updateLogList,
      treeRef,
      titleArr,
      clickChildren,
      handleSelect,
      filterNode,
      getMenuList,
      getUpdateLog,
      clickItem,
      helpScreenQuery,
      ...toRefs(treeProps)
    }
  }
})
